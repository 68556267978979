.bold {
  font-weight: bold;
}

.container {
  position: relative;
}

.create-review {
  text-align: center;
  font-weight: bold;
  margin-top: 20vh;
}

.header {
  font-weight: bold;
  text-align: center;
  margin-top: 2vh;
}

.input {
  border-radius: 5px;
  margin: 2px;
}

.review-subheader {
  margin-left: 20px;
}

.reviews-btn {
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px 10px;
}

.pw {
  position: relative;
  /* margin: auto; */
  text-align: center;
  margin-top: 20vh;
}
