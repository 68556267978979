.carousel-header {
  padding-top: 50px;
}

.carousel-item {
  height: 65vh;
  min-height: 300px;
  filter: brightness(70%);
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.slogan {
  color: whitesmoke;
  /* text-shadow: 1.5px 1.5px 1.5px rgb(114, 114, 114); */
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  z-index: 2;
  position: absolute;
  text-align: center;
  top: 20vh;
  width: 100%;
}

.slogan-text {
  font-size: 1.7rem;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.z-4 {
  z-index: 4;
}

.z-2 {
  z-index: 2;
}

.item-1 {
  background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house.jpg");
}

.item-2 {
  background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house-2.jpg");
}
.item-3 {
  background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house-3.jpg");
}

.get-in-touch-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 8vh;
  margin-bottom: 5vh;
  font-family: "Raleway", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  /* background-color: #39a0ed; */
  background-color: black;

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
}

.get-in-touch-btn:hover {
  /* background-color: #0277bd; */
  background-color: #0d47a1;
  /* border: 2px solid white; */
  color: white;
}
.get-in-touch-btn:active {
  color: white;
}

@media only screen and (min-width: 600px) {
  .get-in-touch-btn {
    width: 25%;
  }
  .slogan {
    top: 22vh;
  }
  .slogan-text {
    font-size: 2.9rem;
  }

  .item-1 {
    background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house.jpg");
  }

  .item-2 {
    background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house-2.jpg");
  }
  .item-3 {
    background-image: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/donovan-house-3.jpg");
  }
}
