.reviews-container {
  /* position: relative; */
  min-height: 100%;
  padding-bottom: 25px;
  text-align: center;
}

.donovan-pic {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
}

.fa-star {
  color: gold;
}

.img-fix {
  margin: 20px 0px;
}

.review-container {
  /* max-width: 90vw; */
  /* border: 2px solid #ccc; */
  background-color: #ebebeb;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* border-radius: 35px; */
  /* height: 350px; */
  width: 80%;
  padding: 0px 16px;
  margin: auto;
  margin-top: 5vh;
  font-family: "Raleway", sans-serif;
}

.review-info {
  display: flex;
  flex-direction: column;
}

.review-text {
  display: flex;
  flex-direction: column;
  font-style: italic;
}

.review-container::after {
  content: "";
  clear: both;
  display: table;
}

.review-container img {
  border-radius: 50%;
}

.review-container span {
  font-size: 20px;
}

.load-more-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 8vh;
  margin-bottom: 5vh;
  font-family: "Montserrat", sans-serif;

  font-size: 0.9rem;
  font-weight: 800;

  padding: 10px;
  color: #000000;
  /* background: rgb(0, 74, 134); */
  /* background: linear-gradient(120deg, #0091ea 50%, #2962ff 100%); */
  background-color: transparent;
  justify-content: center;

  text-decoration: none;
  border: 2px solid #000000;
  /* border-radius: 10px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
}

.load-more-btn:active {
  /* color: white; */
  /* text-decoration: none !important; */
  /* box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1); */

  /* border: 2px solid #eceff1 !important; */
  /* border-color: #eceff1; */

  /* color: #fff; */
  /* transform: translateY(-2px); */
}

/* 
@media (max-width: 600px) {
  .review-container {
    text-align: center;
    margin: 20px;
  }
  .review-container img {
    margin: auto;
    float: none;
    display: block;
  }
} */

@media only screen and (min-width: 600px) {
  .load-more-btn:hover {
    /* color: white; */
    text-decoration: none;
    border-color: #0d47a1;
    background-color: #0d47a1;
    color: white;
  }
  .load-more-btn {
    font-size: 1.1rem;
    font-weight: 800;
  }
  .form-group {
    align-items: center;
  }
  .form-container {
    width: 500px;
  }

  .review-container {
    /* height: 300px; */
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  .contact-submit-btn {
    width: 50%;
  }
}
