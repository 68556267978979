.vg-logo-home {
  width: 100%;
  /* padding: 50px 0px; */
  /* background-color: #eeeeee; */
}
.logo-container {
  position: relative;
  height: 150px;
}

.home-container {
  position: relative;
  /* min-height: 100vh; */
  text-align: center;
  width: 100%;
}

.home-header {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.home-info {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.home-header-text {
  color: white;
  font-size: 1.2rem;
  margin: 0px 20px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.donovan-home-pic {
  width: 180px;
  border-radius: 50%;
  margin-top: -90px;
  margin-bottom: 20px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.reviews-title {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-top: 5vh;
}

.leave-review-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 8vh;
  margin-bottom: 5vh;
  font-family: "Raleway", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  /* background-color: #39a0ed; */
  background-color: black;

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* START PEN */

#hero-home {
  background: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/donovan/house.jpg");
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  position: relative;
  /* top: -20; */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
#hero2 {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/21555/Melissani-cave-natural.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.hero,
.content {
  text-align: center;
  position: relative;
  width: 100%;
}

.inner-header {
  min-height: 400px;
  position: relative;
}

.hero .inner-header {
  background: rgba(61, 61, 61, 0.7)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC)
    repeat;
}

.copy {
  position: absolute;
  top: 50%;
  height: 10em;
  margin-top: -5em;
  width: 100%;
}

.hero h1,
.hero p {
  color: #fff;
}

.content h1,
.content p {
  color: #333;
}

.home-video-container {
  /* margin-bottom: 4vh; */
  /* max-width: 80%; */
  /* background-color: rgb(204, 204, 204); */
  /* padding: 20px; */
  /* margin: 0px 140px; */

  margin-bottom: 4vh;
  /* border-radius: 20pt; */
}

.home-video {
  max-width: 90%;
}

/* END PEN */

/* 
.color-changing-bg {
 
  background: linear-gradient(
    124deg,
    #ff2400,
    #e81d1d,
    #e8b71d,
    #e3e81d,
    #1de840,
    #1ddde8,
    #2b1de8,
    #dd00f3,
    #dd00f3
  );
  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
} */

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

.text-logo {
  margin-left: 5%;
  margin-right: 5%;
  position: fixed;
  top: 10%;
  max-height: 25vh;
  max-width: 100%;
  z-index: 2;
}

.home-image {
  text-align: center;
  margin: 0 auto;
  /* margin-top: 35px; */
  margin-bottom: 15px;
  width: 100%;
}
.custom-header {
  color: rgb(63, 63, 63);
  /* font-family: "Khula", sans-serif;
  font-size: 1.9rem; */
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.9rem;
  text-align: center;
  margin: 20px 0px 10px 0px;
}

.home-info {
  /* color: #fffde7 !important; */
  color: #424242 !important;

  /* margin: 0px 20px; */
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.home-text {
  position: relative;
  color: rgb(63, 63, 63);
  top: 40vh;
  text-align: center;
  font-family: "Khula", sans-serif;
  font-size: 1.9rem;
}

.home-title {
}

.home-bg-color {
  background-color: whitesmoke !important;
}

.home-description {
  display: flex;
  color: black;
  margin: 0px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 41vh;
}

.contact-button:hover {
  color: white;
  text-decoration: none;
}

.contact-button {
  text-align: center;
  text-decoration: none !important;

  display: flex;
  margin: auto;
  margin-top: 5vh;
  /* width: 30%; */
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 25px;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
}

/* START BRADY BOOTSTRAP STUFF */

.about-description {
  /*width: 50%;*/
  padding-right: 5%;
  padding-left: 5%;
  margin-bottom: 4vh;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

.about-job-header {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
}

.about-job-title {
  color: black;
}

.about-photo {
  display: inline-block;
  width: 150px;
  height: 150px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.category-img-container {
  text-align: center;
  /* height: 20vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.category-img {
  display: inline-block;
  height: 10vh;
  margin-bottom: 2vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.home-header-cs-logo {
  /* display: inline-block; */
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 150px;
  height: 150px;

  border-radius: 50%;

  /* background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.services-container {
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding-top: 30px;
  /* padding: 30px !important; */
  /* border: 1px solid black; */
  /* border-radius: 25pt; */
  background-color: #eeeeee !important;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  /* .home-header-bg {
    height: 60vh;
  } */
  .home-image {
    width: 50%;
  }

  .vg-logo-home {
    width: 0%;
    /* padding: 50px 0px; */
    /* background-color: #eeeeee; */
  }

  .contact-button {
    width: 50px;
  }

  /* .home-header-cs-logo {
    margin-top: 5vh;
    width: 200px;
    height: 200px;
  } */
  .category-img {
    display: inline-block;
    height: 12vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

/* Header Section  */
.home-header-bg {
  position: relative;
  padding-top: 20vh;
  width: 100%;
  height: 100vh;
  background-color: #fafafa;
  color: white !important;

  /* border-bottom-left-radius: 25pt;
  border-bottom-right-radius: 25pt; */

  text-align: center;
  margin: 0px;
  overflow: hidden;
}

.home-animation-bg {
  /* background: linear-gradient(-45deg, #0d47a1, #52cfde, #1976d2, #0288d1); */
  background-size: 400% 400%;
  /* animation: gradient 15s ease infinite; */
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-header-bg {
    /* height: 60vh; */
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
