.contact-background {
  background-color: #eeeeee !important;
}

.contact-container {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  /* padding-bottom: 150px; */
}

.lottie-container {
  height: 40px;
  width: 40px;
  position: relative;
  bottom: 53px;
  left: 75%;
}

.contact-content-wrapper {
  margin-top: 30px;
}

.contact-info {
  color: black !important;
  /* margin: 0px 100px; */
  /* padding-left: 150px;
  padding-right: 150px; */
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  text-align: center !important;
  margin: auto;

  margin-top: 5vh;
  margin-bottom: 3vh;

  max-width: 550px;
}

/* .content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
} */

/* .content-wrapper {
  padding-top: 57px;
  padding-bottom: 5vh;
} */

.contact-header {
  /* margin-top: 25px;

  color: rgb(63, 63, 63);
  font-family: "Montserrat", sans-serif;
  font-size: 2.5rem;
  text-align: center; */

  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
  margin-top: 4vh;
}

.alert {
  margin-top: 5vh;
  height: 50px;
  text-align: center;
  color: white;
}

.fail-alert {
  background-color: #e53935;
}
.sending-alert {
  background-color: #42a5f5;
}

.success-alert {
  background-color: #43a047;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.text-logo {
  margin-left: 5%;
  margin-right: 5%;
  position: fixed;
  top: 10%;
  max-height: 25vh;
  max-width: 100%;
  z-index: 2;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}
.custom-header {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: 1.9rem;
  text-align: center;
}

.info {
  color: black;
  margin: 0px 20px;
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.contact-img {
  top: 40vh;
}

.form-message {
  height: 15vh !important;
}

.display-inline-block {
  display: inline-block;
}

.contact-submit-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 8vh;
  margin-bottom: 5vh;
  font-family: "Raleway", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  /* background-color: #39a0ed; */
  background-color: black;

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
}

.contact-submit-btn:hover {
  /* background-color: #0277bd; */
  background-color: #0d47a1;
  /* border: 2px solid white; */
  color: white;
}
.contact-submit-btn:active {
  color: white;
}

@media only screen and (min-width: 600px) {
  .contact-submit-btn {
    width: 25%;
  }
}
/* --------- kbd Button ---------- */
/* .contact-submit-btn {
  text-align: center;
  text-decoration: none !important;

  width: 75%;

  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Raleway", sans-serif;
  font-size: 1.1rem;
  font-weight: 800;
  padding: 15px;
  color: white;
  background-color: #000000;
  justify-content: center;

  text-decoration: none;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

.contact-submit-btn:hover {
  color: white;
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);

  color: #fff;
  transform: translateY(-2px);
} */

/* START BUTTON */
/* 
.contact-button {
  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 25px;
  padding: 15px;
  color: white;
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  border: none;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
} */

/* Laptop screens:  */

.form-container {
  font-family: "Raleway", sans-serif;
}

@media only screen and (min-width: 600px) {
  /* .contact-container {
    margin-bottom: 150px;
  } */
  .form-group {
    align-items: center;
  }
  .form-container {
    width: 500px;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  /* .contact-submit-btn {
    width: 50%;
  } */
}
